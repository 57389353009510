import React from "react"
import LayoutEng from "../../components/LayoutEng"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../../components/SEO"

const Ivana = () => {
  return (
    <LayoutEng>
      <SEO title="Ivana" />
      <main className="page">
      <div className="profilePage">
            <div>
              <StaticImage
              src="../../assets/images/ivana.jpeg"
              alt="Ivana"
              className="ivana-profile-img"
              placeholder="DOMINANT_COLOR"
              />
            </div>
            <div className="profileText">
            <h1>Ivana Mrgan <span className="kurziv"> univ. spec. clin. psych.</span></h1>
                <h3>I'm so glad that you're here!</h3>
                <p>You're probably reading this with a mixture of curiosity, concern, and tons of questions. 
                  All of that is useful, important and necessary in this collaboration that you contemplate. 
                  I guess you decided that the great unfolding is in order, and I look forward to the opportunity to be part of that adventure.
                </p>
                <p>I am passionate about clinical psychology, psychotherapy and people in general. Openness to different experiences, 
                  empathy and authentic relationships are something that defined me in my career. These are also the values that 
                  I will bring with me into our relationship if you decide to work with me. I am particularly keen on openness, compassion, 
                  multiculturalism and a culturally sensitive approach to clients. 
                </p>
                <p>The latter developed and gained momentum during seven years of cross-cultural living and working. 
                  I predominantly worked with first-generation immigrants, expats, internationals and ATCKs in the education 
                  system and an international school, a polyclinic and a mental health community centre. During that period, 
                  I also worked intensively in a corporate environment for several international companies as an EAP affiliate.
                </p>
                <p>In that period, I also completed postgraduate professional studies with a focus on the effectiveness of 
                  culturally adapted Cognitive Behavioural Therapy (CBT).  
                </p>
                <p>In addition to Cognitive-Behavioural Therapy, I also fell in love with Acceptance and Commitment Therapy (ACT), 
                  and in recent years, I have devoted myself more to Schema therapy, which is aimed at difficulties in relationships 
                  and chronic issues. You can read more about what to expect from Shema therapy <Link to="../../../blogEng/schema-therapy-benefits">here</Link>.
                </p>
                <p>Early on in my career, I had a variety of business experiences that resulted in professional training related to 
                  human resources management and career development consulting. 
                </p>
                <p>At that time, I also worked as a guidance counsellor in a school, at the university and collaborated with Ana to 
                create Apsiha, a centre for personal development that we would personally like to walk into...
                </p>
                <p>Besides Apsiha, I have other passions. They are as colourful as my experiences, a testament to a tireless and curious spirit. 
                  There is my family, friends and the international community, parkour, travelling, volunteering, writing and cooking. 
                  I'd love to learn more about yours.
                </p>
                <p>Here are some of the <Link to="../Services">services</Link> we offer.
                </p>
                  <p>Let's unfold your story together!
                </p>
              </div>
              <div className="backBtn">
              <Link to="../About/#ivana"  className="btn">
                &laquo; Back
              </Link>
            </div>
            </div>
      </main>
    </LayoutEng>
  )
}

export default Ivana
